export const environment = {
  production: false,
  api: 'https://api-internal-dev.mjpoints.com/api/v1/',
  googleStorageUrl: 'https://storage.googleapis.com/mjpoints-backend',
  keycloak: {
    production: true,
    oauthServerHost: 'https://auth.mjpoints.com',
    oauthRealm: 'mjpoints-realm',
    oauthClientId: 'mjpoints-public-cli',
    superAdminRole: 'super_user',
    responseType: 'code',
    scope: 'openid profile email offline_access onboarded_scope',
  },
};
